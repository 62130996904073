import React, { Component } from "react";
import "../CSS/theme.css";

import Slider from "react-slick";
import { Link } from "react-router-dom";
import chai from "../img/chai.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { api_url } from "../cofig";
import ItemSliderLoader from "../Loaders/ItemSliderLoader";
export class HomePageItem extends Component {
  constructor() {
    super();
    this.state = {
      releaseList: [],
      recommendedList: [],
      allList: [],
      loadingApi: true,
    };
  }

  componentDidMount() {
    axios.get(`${api_url}media/newReleaseList`).then((res) => {
      const newRelease = res.data;
      this.setState({ releaseList: newRelease });
    });

    axios.get(`${api_url}media/recommendedList`).then((res) => {
      const recommended = res.data;
      this.setState({ recommendedList: recommended });
    });
    axios.get(`${api_url}media/home/list`).then((res) => {
      console.log(res);
      const allLists = res.data;
      this.setState({ allList: allLists, loadingApi: false });
    });
  }

  handleGetId = (e, id) => {
    console.log(id);
    if (this.props.getIdFromClick) {
      this.props.getIdFromClick(id);
    }
    this.props.navigate(`/mediadetail/${id}`);
  };

  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 9,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1350,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    var settingsFor4 = {
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
      ],
    };
    return (
      <>
        <section>
          <div className="container-fluid audio-block pb-5">
            <div className="row audio-list my-2">
              <div className="col-md-12">
                <h4 className="block-title">New Releases</h4>
              </div>
              <div className="col-md-12">
                {this.state.loadingApi ? (
                  <ItemSliderLoader />
                ) : (
                  <div className="zoom">
                    <Slider {...settings}>
                      {this.state.releaseList.map((data, index) => {
                        return (
                          <div key={index}>
                            <div
                              onClick={(e) => this.handleGetId(e, data.id)}
                              role="button"
                              className=" main--thumbnail--media"
                            >
                              <div className="img-loader img-box">
                                <Link className="audio">
                                  <img
                                    src={data.thumbnailUrl}
                                    style={{ width: "100%" }}
                                    alt=""
                                    srcSet=""
                                  />
                                </Link>
                                <div className="audio-detail">
                                  <div className="audio-assets">
                                    <div className="language">
                                      {data.language}
                                    </div>
                                    <div className="duration">
                                      {data.duration} min
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                )}
              </div>
            </div>

            <div className="row audio-list my-2">
              <div className="col-md-12">
                <h4 className="block-title">Recommended For You</h4>
              </div>
              <div className="col-md-12">
                {this.state.loadingApi ? (
                  <ItemSliderLoader />
                ) : (
                  <div className="zoom">
                    <Slider {...settings}>
                      {this.state.recommendedList.map((data, index) => {
                        return (
                          <div key={index}>
                            <div
                              onClick={(e) => this.handleGetId(e, data.id)}
                              role="button"
                              className=" main--thumbnail--media"
                            >
                              <div className="img-loader img-box">
                                <Link className="audio">
                                  <img
                                    src={data.thumbnailUrl}
                                    style={{ width: "100%" }}
                                    alt=""
                                    srcSet=""
                                  />
                                </Link>
                                <div className="audio-detail">
                                  <div className="audio-assets">
                                    <div className="language">
                                      {data.language}
                                    </div>
                                    <div className="duration">
                                      {data.duration} min
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                )}
              </div>
            </div>
            {/* <div className="row audio-list">
              <div className="col-md-12">
                <h4 className="block-title">Stories</h4>
              </div>
              <div className="col-md-12" typeof="new-release-list">
                <div className="variable-new slider zoom width-150 slick-initialized slick-slider">
                  <Slider {...settings}>

                    {this.state.recommendedList.map((data) => {
                      return (
                        <>
                          <div>
                            <div className="img-loader img-box">
                              <Link className="audio">
                                <img
                                  src={data.thumbnailUrl}
                                  style={{ width: "100%" }}
                                  alt=""
                                  srcSet=""
                                />
                              </Link>
                              <div className="audio-detail">
                                <div className="audio-assets">
                                  <div className="language">Hindi</div>
                                  <div className="duration">5</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )
                    })}


                  </Slider>
                </div>
              </div>
            </div> */}

            {this.state.allList.map((data, index) => {
              return (
                <>
                  {data.media.length ? (
                    <div className="row audio-list my-2" key={index}>
                      <div className="col-md-12">
                        <h4 className="block-title">{data.title}</h4>
                      </div>
                      <div className="col-md-12" typeof="new-release-list">
                        <div className="zoom">
                          {data.media.length > 4 ? (
                            <Slider {...settings}>
                              {data.media.map((list, ind) => {
                                return (
                                  <div key={ind}>
                                    <div
                                      onClick={(e) =>
                                        this.handleGetId(e, list.id)
                                      }
                                      role="button"
                                      className=" main--thumbnail--media"
                                    >
                                      <div className="img-loader img-box">
                                        <Link className="audio">
                                          <img
                                            src={list.thumbnailUrl}
                                            style={{ width: "100%" }}
                                            alt=""
                                            srcSet=""
                                          />
                                        </Link>
                                        <div className="audio-detail">
                                          <div className="audio-assets">
                                            <div className="language">
                                              {list.language}
                                            </div>
                                            <div className="duration">
                                              {list.duration ? (
                                                <>{list.duration} min</>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </Slider>
                          ) : (
                            <Slider {...settingsFor4} className="width-150">
                              {data.media.map((list, ind) => {
                                return (
                                  <div key={ind}>
                                    <div
                                      onClick={(e) =>
                                        this.handleGetId(e, list.id)
                                      }
                                      role="button"
                                      className=" main--thumbnail--media"
                                    >
                                      <div className="img-loader img-box">
                                        <Link className="audio">
                                          <img
                                            src={list.thumbnailUrl}
                                            style={{ width: "100%" }}
                                            alt=""
                                            srcSet=""
                                          />
                                        </Link>
                                        <div className="audio-detail">
                                          <div className="audio-assets">
                                            <div className="language">
                                              {list.language}
                                            </div>
                                            <div className="duration">
                                              {list.duration ? (
                                                <>{list.duration} min</>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </Slider>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </div>
        </section>
      </>
    );
  }
}

export default function HomepageItemFunction({ getIdFromClick }) {
  const navigate = useNavigate();
  return (
    <>
      <HomePageItem navigate={navigate} getIdFromClick={getIdFromClick} />
    </>
  );
}
