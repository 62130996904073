import React, { Component } from "react";
import Header from "./Header";
import axios from "axios";
import MediaDetail from "./MediaDetail";
import { useNavigate } from "react-router-dom";
import { api_url } from "../cofig";

export class MoreDetail extends Component {
  constructor() {
    super();
    this.state = {
      listDetail: [],
    };

    console.log(this.langs);

    this.url = window.location.href.split("/");
    console.log(this.url);
  }

  componentDidMount() {
    axios
      .get(
        `${api_url}media/listByPreference?preferenceId=${this.url[4]}`
      )
      .then((res) => {
        console.log(res);
        const list = res.data;
        this.setState({ listDetail: list });
      });
  }

  handleGetId = (e, id) => {
    console.log(id);
    this.props.navigate(`/mediadetail/${id}`);
  };

  render() {
    return (
      <>
        <>
          <header>
            <Header />
          </header>
          <section className="mb-5">
            <div className="container">
              <div className="category">
                <h2 className="heading" id="type">
                  {this.url[5].replace(/%20/g, " ")}
                </h2>
                <div className="row" id="category-content">
                  {this.state.listDetail.map((data,index) => {
                    return (
                        <div
                          className="col-md-2 col-sm-4 col-xs-3 media"
                          onClick={(e) => this.handleGetId(e, data.id)}
                          role="button"
                          key={index}
                        >
                          <div className="audio">
                            <img src={data.thumbnailUrl} alt="" />
                            <div className="audio-detail">
                              <div className="audio-assets">
                                <div className="language">{data.language}</div>
                                <div className="duration">
                                  {data.duration} min
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </>
      </>
    );
  }
}

export default function MoreDetailFunction() {
  const navigate = useNavigate();
  return (
    <>
      <MoreDetail navigate={navigate} />
    </>
  );
}
