import React, { Component } from "react";
// import Swiper core and required modules
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

class BannerSliderLoader extends Component {
  render() {
    return (
      <Swiper
        spaceBetween={12}
        slidesPerView={1.4}
        centeredSlides={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
        loop={true}
      >
        <SwiperSlide>
          <div
            className="slider home-placeholder home-slider"
            style={{ marginTop: "100px" }}
          >
            <div>
              <div className="placeholder-slide home-slider-placeholder" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slider home-placeholder home-slider"
            style={{ marginTop: "100px" }}
          >
            <div>
              <div className="placeholder-slide home-slider-placeholder" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slider home-placeholder home-slider"
            style={{ marginTop: "100px" }}
          >
            <div>
              <div className="placeholder-slide home-slider-placeholder" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div
            className="slider home-placeholder home-slider"
            style={{ marginTop: "100px" }}
          >
            <div>
              <div className="placeholder-slide home-slider-placeholder" />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    );
  }
}

export default BannerSliderLoader;
