import React, { Component } from "react";
import Header from "./Header";
import "../CSS/theme.css";
import chai from "../img/chai.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { api_url } from "../cofig";
import loaderImg from "../img/loader.svg";
export class Personalities extends Component {
  constructor() {
    super();
    this.state = {
      historyList: [],
      loadApi: true,
    };
  }

  componentDidMount() {
    axios.get(`${api_url}media/listByPreference?preferenceId=1`).then((res) => {
      console.log(res);
      const list = res.data;
      this.setState({ historyList: list, loadApi: false });
    });
  }

  handleGetId = (e, id) => {
    console.log(id);
    this.props.navigate(`/mediadetail/${id}`);
  };

  render() {
    return (
      <>
        <header>
          <Header />
        </header>
        <section className="mb-5">
          <div className="container">
            <div className="category">
              <h2 className="heading" id="type">
                Personalities
              </h2>
              <div className="row" id="category-content">
                {/* <div className="col-md-2 col-sm-4 col-xs-3 media">
                        <div className="audio">
                        <img src={chai} alt="" />
                        <div className="audio-detail">
                            <div className="audio-assets">
                                <div className="language">Hindi</div>
                                <div className="duration">10 min</div>
                            </div>
                        </div>
                        </div>
                    </div> */}
                {this.state.loadApi ? (
                  <div className="d-flex justify-content-center align-items-center col-12 my-5">
                    <img src={loaderImg} alt="" srcSet="" />
                  </div>
                ) : (
                  <>
                    {" "}
                    {this.state.historyList.map((data,index) => {
                      return (
                          <div
                            className="col-md-2 col-sm-4 col-xs-3 media"
                            role="button"
                            onClick={(e) => this.handleGetId(e, data.id)}
                            key={index}
                          >
                            <div className="audio">
                              <img src={data.thumbnailUrl} alt="" />
                              <div className="audio-detail">
                                <div className="audio-assets">
                                  <div className="language">
                                    {data.language}
                                  </div>
                                  <div className="duration">
                                    {data.duration} min
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default function PersonalitiesFunction() {
  const navigate = useNavigate();
  return (
    <>
      <Personalities navigate={navigate} />
    </>
  );
}
