import React, { Component, useContext } from "react";
import Header from "./Header";
import "../CSS/theme.css";
import twitter from "../img/icons/social/twitter.png";
import facebook from "../img/icons/social/facebook.png";
import whatsapp from "../img/icons/social/whatsapp.png";
import chai from "../img/chai.jpg";
import axios from "axios";
import HomepageItemFunction from "./HomePageItem";
import { api_url } from "../cofig";
import {
  AudioDetailsContext,
  isPlayingContext,
} from "../Context/AudioDetailsContext";
export class MediaDetail extends Component {
  constructor() {
    super();
    this.state = {
      details: {},
      langs: [],
      id: window.location.href.split("/").pop(),
    };
    console.log(this.langs);
    console.log(this.state.id);
  }

  componentDidMount() {
    axios.get(`${api_url}media/get?id=${this.state.id}`).then((res) => {
      console.log(res);
      this.setState({ details: res.data, langs: res.data.language.title });
      document.title = `NELLO - ${res.data.title}`;
    });
    axios
      .get(`${api_url}media/audio/get?mediaId=${this.state.id}`)
      .then((res) => {
        console.log(res);
        this.props.setAudioDetails(res.data);
      });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  componentDidUpdate(prevProp, prevState, snapShot) {
    // console.log(prevProp);
    // console.log(this.props);
    if (prevState.id !== this.state.id) {
      axios.get(`${api_url}media/get?id=${this.state.id}`).then((res) => {
        console.log(res);
        this.setState({ details: res.data, langs: res.data.language.title });
        document.title = `NELLO - ${res.data.title}`;
      });
      axios
        .get(`${api_url}media/audio/get?mediaId=${this.state.id}`)
        .then((res) => {
          console.log(res);
          this.props.setAudioDetails(res.data);
        });
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }

  getIdFromClick = (id) => {
    console.log(id, "media details");
    this.setState({ id: id });
  };

  render() {
    return (
      <>
        <header>
          <Header />
        </header>

        <section className="mb-5">
          <div className="container-fluid no-padding">
            <div className="row align-items-center row-content">
              <div className="right-col">
                <div className="series-container" style={{ height: "520px" }}>
                  <div className="series-cover"></div>
                  <div className="container row series-details">
                    <div
                      className="col-lg-3 col-md-3 col-sm-12 col-xs-12"
                      style={{ textAlign: "center" }}
                    >
                      <div id="mediaCoverImage">
                        <img
                          src={this.state.details.thumbnail}
                          alt=""
                          className="img-responsive"
                          style={{ maxWidth: "265px" }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-sm-12 col-xs-12">
                      <div className="series-desc">
                        <h3 id="mediaTitle">{this.state.details.title}</h3>
                        <p id="mediaDescription">
                          {this.state.details.description}
                        </p>
                      </div>
                      <div className="meta-info">
                        <dl>
                          <dt>Language</dt>
                          <dd>{this.state.langs}</dd>
                        </dl>
                      </div>
                      <div className="row media-play-share">
                        <div className="col-md-4" id="btnPlayMedia">
                          <button
                            className="btn audio width-100 btn-play"
                            id="27"
                            onClick={() => {
                              this.props.setIsPlaying(!this.props.isPlaying);
                            }}
                          >
                            {this.props.isPlaying ? "Pause" : "Play"}
                          </button>
                        </div>
                        <div className="col-md-8">
                          <span className="social-share twitter">
                            <img src={twitter} alt="" srcSet="" />
                          </span>
                          <span className="social-share twitter">
                            <img src={facebook} alt="" srcSet="" />
                          </span>
                          <span className="social-share twitter">
                            <img src={whatsapp} alt="" srcSet="" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HomepageItemFunction getIdFromClick={this.getIdFromClick} />
        </section>
      </>
    );
  }
}

// export default MediaDetail;

const MediaDetailFunction = () => {
  const [audioDetails, setAudioDetails] = useContext(AudioDetailsContext);
  const [isPlaying, setIsPlaying] = useContext(isPlayingContext);

  return (
    <MediaDetail
      audioDetails={audioDetails}
      setAudioDetails={setAudioDetails}
      isPlaying={isPlaying}
      setIsPlaying={setIsPlaying}
    />
  );
};

export default MediaDetailFunction;
