import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <div className="container">
        <div
          className="mt-5 mb-4 align-items-center footer-main"
        >
          <div className=" justify-content-end" style={{ textAlign: "left" }}>
            Copyright © NELLO. All Rights Reserved
          </div>

          <div className="footer-items" style={{ textAlign: "right" }}>
            <Link to="/privacy-policy" style={{ color: "#fff" }}>Privacy policy</Link>
            <Link to="/terms-and-conditions" style={{ color: "#fff", marginLeft: "1em" }}>Term & Conditions</Link>
            <Link to="/delete-your-account" style={{ color: "#fff", marginLeft: "1em" }}>Delete Your Account</Link>
          </div>

        </div>
      </div>
    );
  }
}

export default Footer;
