import React, { Component } from "react";
import Header from "./Header";
// import Swiper core and required modules
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../CSS/Styles.css";
import playbutton from "../img/icons/play-button-pink.png";
import Modal from "react-bootstrap/Modal";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import HomepageItemFunction from "./HomePageItem";
import { api_url } from "../cofig";
import BannerSliderLoader from "../Loaders/BannerSliderLoader";

export class Homepage extends Component {
  constructor() {
    super();
    this.state = {
      sliderList: [],
      loadingApi: true,
      showModal: false,
    };

    // this.navigate = this.props.history
  }

  componentDidMount() {
    axios.get(`${api_url}slider/list`).then((res) => {
      console.log(res);
      const list = res.data;
      this.setState({ sliderList: list, loadingApi: false });
    });
    setTimeout(() => {
      this.setState({ showModal: true });
    }, 5000);
  }

  handleGetId = (e, id) => {
    console.log(id);
    this.props.navigate(`/mediadetail/${id}`);
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <>
        <header>
          <Header />
        </header>

        {/* <div className="modal" id="appButtonModal" role="dialog"> */}
        <Modal size="md" show={this.state.showModal} onHide={this.closeModal}>
          {/* <div className="modal-dialog modal-sm"> */}
          <div style={{ backgroundColor: "#191f2b", color: "#fff" }}>
            <button
              type="button"
              className="close--download"
              data-dismiss="modal"
              onClick={() => this.closeModal()}
            >
              &times;
            </button>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12" align="center">
                  {/* <!-- <div id="premiumErrorMessage"></div> --> */}
                  <img src="img/nello-home.svg" className="img-responsive" />
                  <p>
                    <i>Download App Now!</i>
                  </p>
                </div>
                <div className="col-md-12" align="center">
                  <button type="button" className="btn btn-social">
                    <img className="img-responsive" src="img/aplleapk.png" />
                  </button>
                </div>
                <div className="col-md-12" align="center">
                  <button type="button" className="btn btn-social">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.nello.app"
                      download
                    >
                      <img
                        className="img-responsive"
                        src="img/androidapk.png"
                      />
                    </a>
                  </button>
                </div>
                <div className="col-md-12" align="center">
                  <p className="terms">
                    By Downloading App, you can enjoy our Latest Stories
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </Modal>

        <section className="mb-5">
          {this.state.loadingApi ? (
            <BannerSliderLoader />
          ) : (
            <Swiper
              spaceBetween={12}
              slidesPerView={1.4}
              centeredSlides={true}
              autoplay={{
                delay: 3500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              modules={[Autoplay, Pagination, Navigation]}
              className="mySwiper"
              loop={true}
            >
              {this.state.sliderList.map((data, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className="audio hello"
                      role="button"
                      onClick={(e) => this.handleGetId(e, data.media.id)}
                    >
                      <img src={data.largeImageUrl} alt="" srcSet="" />
                      <div className="home-slider-content">
                        <div className="home-slider-description">
                          <h5 className="d-flex justify-content-start">
                            {data.title}
                          </h5>
                          <p style={{ textAlign: "left" }}>
                            {data.description}
                          </p>
                          <p>
                            <img className="play" src={playbutton} alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}

          <HomepageItemFunction />
        </section>
      </>
    );
  }
}

// export default Homepage;

export default function HomepageFunction() {
  const navigate = useNavigate();
  return (
    <>
      <Homepage navigate={navigate} />
    </>
  );
}
