import React, { Component } from "react";
import Header from "./Header";
import "../CSS/theme.css";
import stories from "../img/stories.jpg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { api_url } from "../cofig";
import loaderImg from "../img/loader.svg";
export class MoreItem extends Component {
  constructor() {
    super();
    this.state = {
      historyList: [],
      loadApi: true,
    };
  }

  componentDidMount() {
    axios.get(`${api_url}preference/active-list`).then((res) => {
      console.log(res);
      const list = res.data;
      this.setState({ historyList: list, loadApi: false });
    });
  }
  handleGetId = (e, id, title) => {
    // console.log(id, title);
    this.props.navigate(`/moredetail/${id}/${title}`);
  };

  render() {
    return (
      <>
        <header>
          <Header />
        </header>
        <section className="mb-5">
          <div className="container-fluid no-padding">
            <div className="row align-items-center row-content">
              <div className="left-col"></div>
              <div className="right-col">
                <div className="container">
                  <div className="preferences">
                    <h2 className="heading">Categories</h2>
                    <div className="row" id="preference">
                      {this.state.loadApi ? (
                        <div className="d-flex justify-content-center align-items-center col-12 my-5">
                          <img src={loaderImg} alt="" srcSet="" />
                        </div>
                      ) : (
                        <>
                          {this.state.historyList.map((data,index) => {
                            return (
                                <div
                                  className="col-md-5th-1 col-xs-6 preference-box"
                                  role="button"
                                  onClick={(e) =>
                                    this.handleGetId(e, data.id, data.title)
                                  }
                                  key={index}
                                >
                                  <div className="preference-data">
                                    <img
                                      className="img-responsive"
                                      src={data.thumbnailUrl}
                                      alt=""
                                      srcSet=""
                                    />
                                    <div className="preference-content bg22">
                                      <h5 className="preference-title">
                                        {data.title}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default function MoreItemFunction() {
  const navigate = useNavigate();
  return (
    <>
      <MoreItem navigate={navigate} />
    </>
  );
}
