import React, { Component } from "react";
import Header from "./Header";

class DeleteYourAccount extends Component {
  componentDidMount() {
    // Use setTimeout to ensure scrolling happens after the component has fully rendered
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 100);
  }
  render() {
    return (
      <section>
        <Header />
        <div
          class="slider-area2 container-fluid d-flex justify-content-center align-items-center"
          style={{
            color: "#fff",
            background: "#ec3a71",
            height: "100px",
            position: "relative",
          }}
        >
          <img
            src="/icons/dot-icon.svg"
            style={{
              position: "absolute",
              height: "80px",
              left: "-10px",
              opacity:0.4,
              top: "-10px",
            }}
          />
          <div class="slider-height2 d-flex align-items-center">
            <div class="container">
              <div class="row">
                <div class="col-xl-12">
                  <div class="hero-cap text-center">
                    <h2>Delete Your Account</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid no-padding">
          <div className="row align-items-center row-content">
            <div className="right-col">
              <div className="container" style={{ minHeight: "50vh" }}>
                <div style={{ marginTop: "50px", color: "#fff" }}>
                  <h2>Delete Your Account</h2>
                  <p>
                    If you delete your account, your personal information will
                    be deleted permanently. You can delete your account from the
                    Nello app only.
                  </p>
                  <p>To delete your account from your Nello app:</p>
                  <ol>
                    <li>Login to your account using the mobile app.</li>
                    <li>Click on the profile Icon on the bottom right.</li>
                    <li>Scroll down and click on Delete Account.</li>
                    <li>Click on Delete to delete the account.</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DeleteYourAccount;
