import React, { Component } from "react";
import Header from "./Header";
import "../CSS/theme.css";
import chai from "../img/chai.jpg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import loaderImg from "../img/loader.svg";
import { api_url } from "../cofig";
export class Stories extends Component {
  constructor() {
    super();
    this.state = {
      historyList: [],
      loadingApi: true,
    };
  }

  componentDidMount() {
    axios
      .get(`${api_url}media/listByPreference?preferenceId=11`)
      .then((res) => {
        console.log(res);
        const list = res.data;
        this.setState({ historyList: list, loadingApi: false });
      });
  }

  handleGetId = (e, id) => {
    console.log(id);
    this.props.navigate(`/mediadetail/${id}`);
  };

  render() {
    return (
      <>
        <header>
          <Header />
        </header>
        <section className="mb-5">
          <div className="container">
            <div className="category">
              <h2 className="heading" id="type">
                Stories
              </h2>
              <div className="row" id="category-content">
                {this.state.loadingApi ? (
                  <div className="d-flex justify-content-center align-items-center col-12 my-5">
                    <img src={loaderImg} alt="" srcSet="" />
                  </div>
                ) : (
                  <>
                    {this.state.historyList.map((data,index) => {
                      return (
                          <div
                            className="col-md-2 col-sm-4 col-xs-3 media"
                            onClick={(e) => this.handleGetId(e, data.id)}
                            role="button"
                            key={index}
                          >
                            <div className="audio">
                              <img src={data.thumbnailUrl} alt="" />
                              <div className="audio-detail">
                                <div className="audio-assets">
                                  <div className="language">
                                    {data.language}
                                  </div>
                                  <div className="duration">
                                    {data.duration} min
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default function StoriesFunction() {
  const navigate = useNavigate();
  return (
    <>
      <Stories navigate={navigate} />
    </>
  );
}
