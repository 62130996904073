import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../CSS/index.css";
import "../CSS/theme.css";
import nelloHome from "../img/nello-home.svg";
import googleLoginImg from "../img/google_login.png";
import facebookLoginImg from "../img/facebook_login.png";
import nelloLogo from "../img/nello-gradient.png";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "@greatsumini/react-facebook-login";

export class Header extends Component {
  /**Mobile menu */
  openNav() {
    document.getElementById("mySidenav").style.width = "250px";
  }

  closeNav() {
    console.log("closenav");
    document.getElementById("mySidenav").style.width = "0";
    console.log("closenav2");
  }

  // toggleLoginModal() {
  //   // $("#loginModal").modal('show');
  //   // this.closeNav();
  //   $('document').ready(function() {
  //     $('#btnUserLogin').click(function() {
  //       $('#loginModal').modal('show');
  //     });
  //   });
  // }
  responseFacebook(response) {
    console.log(response);
    // Do something with the Facebook access token and user data
  }

  handleLogin = (e) => {
    this.closeNav();
  };
  responseMessage = (response) => {
    console.log(response);
  };
  errorMessage = (error) => {
    console.log(error);
  };

  render() {
    return (
      <>
        <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-header navbar--header--desktop">
          <div className="container-fluid header-top">
            <Link className="navbar-brand logo" to="/">
              <img className="img-logo" src={nelloLogo} />
            </Link>
            <button
              className="navbar-toggler"
              style={{ color: "#fff !important" }}
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/stories">
                    STORIES
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/history" className="nav-link">
                    HISTORY
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/kids" className="nav-link">
                    KIDS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/personalities" className="nav-link">
                    PERSONALITIES
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/business" className="nav-link">
                    Business & Startups
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/more" className="nav-link">
                    MORE
                  </Link>
                </li>

                <li
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                  className="user-login"
                >
                  <button
                    className="btn"
                    id="btnUserLogin"
                    data-toggle="modal"
                    data-target="#loginModal"
                    onClick={this.handleLogin}
                  >
                    Login
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div>
          {/* Mobile Navigation */}
          <div id="mySidenav" className="sidenav">
            <div className="user-details">
              <div className="profile-image" style={{ display: "none" }}>
                <img src="img/icons/profile-icon-mobile.svg" />
              </div>
              <div className="user-info"></div>
              <div className="loginBtnWrapper">
                <button
                  className="btn"
                  id="btnUserLogin"
                  data-toggle="modal"
                  data-target="#loginModal"
                  onClick={this.handleLogin.bind(this)}
                >
                  <i className="fa fa-key" />
                  Login
                </button>
              </div>
            </div>
            <div id="mobile_menu"></div>
            <div className="profile-links">
              {/* <a href="#">My Profile</a> */}
              <Link className="profile-links" to="/stories">
                STORIES
              </Link>
              <Link to="/history" className="profile-links">
                HISTORY
              </Link>
              <Link to="/kids" className="profile-links">
                KIDS
              </Link>
              <Link to="/personalities" className="profile-links">
                PERSONALITIES
              </Link>
              <Link to="/business" className="profile-links">
                Business & Startups
              </Link>
              <Link to="/more" className="profile-links">
                MORE
              </Link>
              {/*<a href="my-list.html">My List</a>*/}
              {/* <a onclick="logout()" href="#">
                Logout
              </a> */}
            </div>
            <a
              // href="javascript:void(0)"
              className="closebtn"
              type="button"
              onClick={() => this.closeNav()}
            >
              x
            </a>
          </div>
          {/* Mobile Navigation End*/}
          {/* Navigation */}
          <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-header">
            {/*Mobile Header*/}
            <div className="container-fluid header-mobile">
              <div className="row">
                <div className="col-4">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => {
                      this.openNav();
                    }}
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                </div>
                <div className="col-4">
                  <a className="navbar-brand logo" href="index.html">
                    <img className="img-logo" src={nelloLogo} />
                  </a>
                </div>
                <div className="col-4"></div>
              </div>
            </div>
            {/*Mobile Header End*/}
            {/* <div className="container-fluid header-top header-desktop">
            <a className="navbar-brand logo" href="index.html"><img className="img-logo" src="img/nello-gradient.png" /></a>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto" id="main_menu">
              </ul>
              <div className="pull-right user-login">
                <ul className="navbar-nav pull-right">
                  <li id="loginBlock"><button className="btn" id="btnUserLogin" data-toggle="modal" data-target="#loginModal">Login</button></li>
                  <li className="dropdown" id="userProfileBlock">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">
                      <img src="img/icons/profile-icon.svg" className="profile-image" /><span id="userName" /></a>
                    <ul className="dropdown-menu dropdown-menu-right">
                      <li className="nav-item"><a className="nav-link" href="profile.html">
                          <img src="img/icons/profile-single.svg" />
                          Profile</a></li>
                      <li className="nav-item" style={{display: 'none'}}><a className="nav-link" href="my-list.html">
                          <img src="img/icons/my-list.svg" />
                          My List</a>
                      </li><li className="nav-item" style={{display: 'none'}}><a className="nav-link" href="settings.html">
                          <img src="img/icons/settings.svg" />
                          Settings</a>
                      </li>
                      <li className="nav-item"><a className="nav-link" onclick={() => {this.logout()}} href="#">
                          <img src="img/icons/logout.svg" />
                          Logout</a></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
          </nav>
          <div className="modal" id="loginModal" role="dialog">
            <div className="modal-dialog modal-sm">
              <button type="button" className="close" data-dismiss="modal">
                ×
              </button>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12" align="center">
                    <div id="premiumErrorMessage" />
                    <img src={nelloHome} className="img-responsive" />
                    <p>
                      <i>Log in to listen amazing audios!</i>
                    </p>
                  </div>
                  <div className="col-md-12" align="center">
                    <button
                      type="button"
                      className="btn btn-social"
                      id="btnGoogleLogin"
                    >
                      {/* <img
                        className="img-responsive"
                        style={{ width: "250px" }}
                        src={googleLoginImg}
                      /> */}
                      <GoogleLogin
                        onSuccess={this.responseMessage}
                        onError={this.errorMessage}
                      />
                    </button>
                  </div>
                  <div className="col-md-12" align="center">
                    {/* <button
                      type="button"
                      className="btn btn-social"
                      onClick="fb_login();"
                    >
                      <img
                        className="img-responsive"
                        style={{ width: "250px" }}
                        src={facebookLoginImg}
                      />
                    </button> */}
                    {/* <FacebookLogin
                      appId="800141340384168"
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                    /> */}
                    <FacebookLogin
                      appId="800141340384168"
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      render={(renderProps) => (
                        <button
                          onClick={renderProps.onClick}
                          style={{
                            background: "transparent",
                            border: "none",
                            outline: "none",
                            marginTop: "8px",
                          }}
                        >
                          <img
                            className="img-responsive"
                            style={{ width: "250px" }}
                            src={facebookLoginImg}
                          />
                        </button>
                      )}
                      size="small"
                      textButton="Login with Facebook"
                    />
                  </div>
                  <div className="col-md-12" align="center">
                    <p className="terms">
                      By Signing Up, you agree to our Terms and Privacy Policy
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Header;
