import "./App.css";
import "./CSS/index.css";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

import Header from "./Componets/Header";
import HomeSlider from "./Componets/HomeSlider";
import Stories from "./Componets/Stories";
import History from "./Componets/History";
import Kids from "./Componets/Kids";
import Business from "./Componets/Business";
import Personalities from "./Componets/Personalities";
import MoreItem from "./Componets/MoreItem";
import MediaDetailFunction from "./Componets/MediaDetail";
import HomepageFunction from "./Componets/Homepage";
import HistoryFunction from "./Componets/History";
import StoriesFunction from "./Componets/Stories";
import KidsFunction from "./Componets/Kids";
import BusinessFunction from "./Componets/Business";
import PersonalitiesFunction from "./Componets/Personalities";
import MoreDetail from "./Componets/MoreDetail";
import MoreDetailFunction from "./Componets/MoreDetail";
import Footer from "./Componets/Footer";
import {
  AudioDetailsContext,
  isPlayingContext,
} from "./Context/AudioDetailsContext";
import { useState } from "react";
import MediaPlayerFunction from "./Componets/MediaPlayer";
import PrivacyPolicy from "./Componets/PrivacyPolicy";
import TermsAndConditions from "./Componets/TermsAndConditions";
import DeleteYourAccount from "./Componets/DeleteYourAccount";

function App() {
  const [audioDetails, setAudioDetails] = useState({});
  const [isPlaying, setIsPlaying] = useState(false);
  return (
    <>
      <isPlayingContext.Provider value={[isPlaying, setIsPlaying]}>
        <AudioDetailsContext.Provider value={[audioDetails, setAudioDetails]}>
          {/* <Router> */}
          <Routes>
            <Route exact path="/" element={<HomepageFunction />} />
            <Route exact path="/stories" element={<StoriesFunction />} />
            <Route exact path="/history" element={<HistoryFunction />} />
            <Route exact path="/kids" element={<KidsFunction />} />
            <Route exact path="/business" element={<BusinessFunction />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route exact path="/delete-your-account" element={<DeleteYourAccount />} />
            <Route
              exact
              path="/personalities"
              element={<PersonalitiesFunction />}
            />
            <Route exact path="/more" element={<MoreItem />} />
            <Route
              exact
              path="/mediadetail/:id"
              element={<MediaDetailFunction />}
            />
            <Route
              exact
              path="/moredetail/:id/:title"
              element={<MoreDetailFunction />}
            />

            {/* <Login/> */}
            {/* <HomeSlider/> */}
          </Routes>
          {/* </Router> */}
          <Footer />
          <MediaPlayerFunction />
        </AudioDetailsContext.Provider>
      </isPlayingContext.Provider>
    </>
  );
}

export default App;
