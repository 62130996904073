import React, { Component } from "react";

class ItemSliderLoader extends Component {
  render() {
    return (
      <div className="variable slider zoom" style={{ display: "flex" }}>
        <div
          className="img-loader img-box zoom width-150"
          // style={{ margin: "0 10px" }}
        >
          <div className="content-placeholder single"></div>
          <div className="desc">
            <div
              className="audio-assets content-placeholder-bottom"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
        <div className="img-loader img-box">
          <div className="content-placeholder single"></div>
          <div className="desc">
            <div
              className="audio-assets content-placeholder-bottom"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
        <div className="img-loader img-box">
          <div className="content-placeholder single"></div>
          <div className="desc">
            <div
              className="audio-assets content-placeholder-bottom"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
        <div className="img-loader img-box">
          <div className="content-placeholder single"></div>
          <div className="desc">
            <div
              className="audio-assets content-placeholder-bottom"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
        <div className="img-loader img-box">
          <div className="content-placeholder single"></div>
          <div className="desc">
            <div
              className="audio-assets content-placeholder-bottom"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
        <div className="img-loader img-box">
          <div className="content-placeholder single"></div>
          <div className="desc">
            <div
              className="audio-assets content-placeholder-bottom"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
        <div className="img-loader img-box">
          <div className="content-placeholder single"></div>
          <div className="desc">
            <div
              className="audio-assets content-placeholder-bottom"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
        <div className="img-loader img-box">
          <div className="content-placeholder single"></div>
          <div className="desc">
            <div
              className="audio-assets content-placeholder-bottom"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
        <div className="img-loader img-box">
          <div className="content-placeholder single"></div>
          <div className="desc">
            <div
              className="audio-assets content-placeholder-bottom"
              style={{ display: "block" }}
            ></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemSliderLoader;
