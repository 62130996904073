import React, { Component, useContext } from "react";
import { Howl, Howler } from "howler";
import playImg from "../icons/play.svg";
import pauseImg from "../icons/pause.svg";
import forwardImg from "../icons/forward.svg";
import backwardImg from "../icons/backward.svg";
import volumeSmallImg from "../img/icons/volume-small.png";

import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
// import 'react-h5-audio-player/lib/styles.less' Use LESS
// import 'react-h5-audio-player/src/styles.scss' Use SASS

import "../CSS/theme.css";
import {
  AudioDetailsContext,
  isPlayingContext,
} from "../Context/AudioDetailsContext";
export class MediaPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      progress: 0,
      duration: 0,
      volume: 0.5,
      loading: true,
    };
    setTimeout(() => {
      this.audio = new Howl({
        src: this.props.audioDetails.mediaUrl,
        html5: true,
        onplay: () => {
          this.props.setIsPlaying(true);
          this.startProgressLoop();
        },
        onpause: () => {
          this.props.setIsPlaying(false);
          this.stopProgressLoop();
        },
        onstop: () => {
          this.props.setIsPlaying(false);
          this.setState({ progress: 0 });
          this.stopProgressLoop();
        },
        onend: () => {
          this.props.setIsPlaying(false);
          this.setState({ progress: 0 });
          this.stopProgressLoop();
        },
        onload: () => {
          this.setState({ duration: this.audio.duration(), loading: false });
        },
      });

      this.playPause = this.playPause.bind(this);
      this.setProgress = this.setProgress.bind(this);
      Howler.volume(this.state.volume);
    }, 2500);
  }

  // componentDidMount() {

  // }
  componentDidUpdate(prevProp, prevState, snapShot) {
    if (prevProp.audioDetails.id !== this.props.audioDetails.id) {
      console.log("61");
      this.setState({
        progress: 0,
        duration: 0,
        loading: true,
      });
      this.props.setIsPlaying(false);
      setTimeout(() => {
        this.audio = new Howl({
          src: this.props.audioDetails.mediaUrl,
          html5: true,
          onplay: () => {
            this.props.setIsPlaying(true);
            this.startProgressLoop();
          },
          onpause: () => {
            this.props.setIsPlaying(false);
            this.stopProgressLoop();
          },
          onstop: () => {
            this.props.setIsPlaying(false);
            this.setState({ progress: 0 });
            this.stopProgressLoop();
          },
          onend: () => {
            this.props.setIsPlaying(false);
            this.setState({ progress: 0 });
            this.stopProgressLoop();
          },
          onload: () => {
            this.setState({ duration: this.audio.duration(), loading: false });
          },
        });

        this.playPause = this.playPause.bind(this);
        this.setProgress = this.setProgress.bind(this);
        Howler.volume(this.state.volume);
        this.audio.play();
      }, 2500);
      this.audio.pause();
    }
  }

  componentWillUnmount() {
    console.log("unmount");
    this.audio.unload();
    this.stopProgressLoop();
  }

  startProgressLoop() {
    this.progressLoop = setInterval(() => {
      this.setState({ progress: this.audio.seek() });
    }, 100);
  }

  stopProgressLoop() {
    clearInterval(this.progressLoop);
  }

  playPause() {
    if (this.audio.playing()) {
      this.audio.pause();
    } else {
      this.audio.play();
      console.log(this.state.progress);
    }
  }

  setProgress(e) {
    const progress = e.target.value;
    console.log(progress);
    console.log("Audio duration:", this.audio.duration());
    this.audio.seek(progress);

    console.log("After seek:", this.audio.seek());
    this.setState({ progress });
  }

  handleVolumeChange = (event) => {
    const volume = parseFloat(event.target.value);
    this.setState({ volume });
    Howler.volume(volume);
  };

  handleBackward = () => {
    if (this.state.progress > 4) {
      this.setState({ progress: this.state.progress - 5 });
      this.audio.seek(this.state.progress - 5);
    } else {
      this.setState({ progress: 0 });
      this.audio.seek(0);
    }
  };
  handleForward = () => {
    if (this.state.duration - 5 > this.state.progress) {
      this.setState({ progress: this.state.progress + 5 });
      this.audio.seek(this.state.progress + 5);
    } else {
      this.setState({ progress: this.state.duration });
      this.audio.seek(this.state.duration);
    }
  };

  secondsToMinutes(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.ceil(seconds % 60);
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  }

  render() {
    const { progress, duration } = this.state;
    const { isPlaying } = this.props;
    return (
      <>
        <div className="custom--media--player">
          <div className="custom--media--seek--length">
            <input
              type="range"
              min={0}
              max={duration}
              value={progress}
              onChange={this.setProgress}
              className="custom--seek--input"
              disabled={this.state.loading}
            />
          </div>
          <div className="main--customization row container-fluid">
            <div className="col-4 d-flex align-items-center">
              <img
                src={this.props.audioDetails.thumbnail}
                alt=""
                srcSet=""
                className={`${
                  isPlaying ? "thumnail--img--playing" : "thumnail--img--stop"
                }`}
              />
              <span className="player--audio--title">
                {this.props.audioDetails.audioTitle}
              </span>{" "}
              <span className="player--audio--type">
                - {this.props.audioDetails.artist}
              </span>
            </div>
            <div className="col-4 d-flex justify-content-center">
              <button
                onClick={this.handleBackward.bind(this)}
                className="forward--backward--btn"
                disabled={this.state.loading}
              >
                <img
                  src={backwardImg}
                  alt=""
                  srcSet=""
                  className="forward--backward--img"
                />
              </button>
              <button onClick={this.playPause} disabled={this.state.loading}>
                {isPlaying ? (
                  <img
                    src={pauseImg}
                    alt=""
                    srcSet=""
                    className="play--pause--img"
                  />
                ) : (
                  <img
                    src={playImg}
                    alt=""
                    srcSet=""
                    className="play--pause--img"
                  />
                )}
              </button>
              <button
                onClick={this.handleForward.bind(this)}
                className="forward--backward--btn"
                disabled={this.state.loading}
              >
                <img
                  src={forwardImg}
                  alt=""
                  srcSet=""
                  className="forward--backward--img"
                />
              </button>
            </div>
            <div className="col-4 d-flex justify-content-end align-items-center">
              <img src={volumeSmallImg} alt="" srcSet="" />
              <div className="custom--media--seek--volume mx-1 d-flex justify-content-end align-items-center">
                <input
                  type="range"
                  min="0"
                  max="1"
                  step="0.01"
                  value={this.state.volume}
                  onChange={this.handleVolumeChange}
                />
              </div>
              <div className="custom--media--duration--timeline">
                <span className="custom--player--progress">
                  {this.secondsToMinutes(progress)}
                </span>
                <span className="custom--player--duration">
                  /{this.secondsToMinutes(duration)}
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <div id="jquery_jplayer_1" className="jp-jplayer">
          <div
            id="jp_container_1"
            className="jp-audio"
            role="application"
            aria-label="media player"
          >
            <div className="jp-type-playlist">
              <div className="jp-gui jp-interface">
                <div className="jp-details">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <div className="jp-poster" id="jpPoster">
                            <img />
                          </div>
                        </td>
                        <td>
                          <div className="jp-title" aria-label="title">
                            &nbsp;
                          </div>
                        </td>
                        <td>-&nbsp;</td>
                        <td>
                          <div
                            className="jp-artist"
                            id="jpArtist"
                            aria-label="artist"
                          >
                            &nbsp;
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="jp-controls">
                  <button className="jp-previous" role="button" tabIndex={0}>
                    previous
                  </button>
                  <button
                    className="jp-backward"
                    id="backward"
                    role="button"
                    tabIndex={0}
                  >
                    Backward
                  </button>
                  <button className="jp-play" role="button" tabIndex={0}>
                    play
                  </button>
                  <button
                    className="jp-forward"
                    id="forward"
                    role="button"
                    tabIndex={0}
                  >
                    Forward
                  </button>
                  <button className="jp-next" role="button" tabIndex={0}>
                    next
                  </button>
                  <button className="jp-stop" role="button" tabIndex={0}>
                    stop
                  </button>
                </div>
                <div className="jp-progress">
                  <div className="jp-seek-bar">
                    <div className="jp-play-bar" />
                  </div>
                </div>
                <div className="jp-volume-controls">
                  <button className="jp-mute" role="button" tabIndex={0}>
                    mute
                  </button>
                  <button className="jp-volume-max" role="button" tabIndex={0}>
                    max volume
                  </button>
                  <div className="jp-volume-bar">
                    <div className="jp-volume-bar-value" />
                  </div>
                </div>
                <div className="jp-time-holder">
                  <div
                    className="jp-current-time"
                    role="timer"
                    aria-label="time"
                  >
                    &nbsp;
                  </div>
                  <div
                    className="jp-duration"
                    role="timer"
                    aria-label="duration"
                  >
                    &nbsp;
                  </div>
                </div>
                <div className="jp-toggles">
                  <button className="jp-repeat" role="button" tabIndex={0}>
                    repeat
                  </button>
                  <button className="jp-shuffle" role="button" tabIndex={0}>
                    shuffle
                  </button>
                </div>
              </div>
              <div className="jp-playlist">
                <ul>
                  <li>&nbsp;</li>
                </ul>
              </div>
              <div className="jp-no-solution" style={{ display: "none" }}>
                <span>Update Required</span>
                To play the media you will need to either update your browser to
                a recent version or update your{" "}
                <a href="" target="_blank">
                  Flash plugin
                </a>
                .
              </div>
            </div>
          </div>
        </div> */}
      </>
    );
  }
}

// export default MediaPlayer;

const MediaPlayerFunction = () => {
  const [audioDetails, setAudioDetails] = useContext(AudioDetailsContext);
  const [isPlaying, setIsPlaying] = useContext(isPlayingContext);

  if (audioDetails.mediaUrl) {
    return (
      <MediaPlayer
        audioDetails={audioDetails}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
      />
      // <AudioPlayer
      //   src={audioDetails.mediaUrl}
      //   customControlsSection={[
      //     <div>
      //       <img
      //         src={audioDetails.thumbnail}
      //         alt=""
      //         srcSet=""
      //         className="thumnail--img--stop"
      //       />
      //     </div>,
      //     // RHAP_UI.ADDITIONAL_CONTROLS,
      //     RHAP_UI.MAIN_CONTROLS,
      //     RHAP_UI.VOLUME_CONTROLS,
      //   ]}
      // />
      // <audio
      //   src={audioDetails.mediaUrl}
      //   autoPlay
      //   controls
      //   style={{ position: "fixed", bottom: "0", left: "0" }}
      // />
    );
  }
};

export default MediaPlayerFunction;
